import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const MobileContainer = Styled.div`
  display: block;
  width: 100%;

  ${Media.greaterThan('medium')`
    display: none;
  `}
`;

export const DesktopContainer = Styled.div`
  display: none;

  ${Media.greaterThan('medium')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 4rem 0;
  `}
`;

export const TextContainer = Styled.div`
  width: 50%;
  padding-right: 4rem;
`;

export const Input = Styled.input`
  height: 4.7rem;
  width: 100%;
  max-width: 45rem;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.body.family};
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: -0.1px;
  padding: ${props => props.theme.spacing.medium};
  margin-top: -1rem;
  outline: none;
  

`;

export const PreviewContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 26rem;
  width: 100%;
  background-color: ${props => props.theme.colors.lightYellow};
  border: 3px dashed ${props => props.theme.colors.yellow};
  border-radius: 10px;
  padding: 6rem;

  ${Media.greaterThan('medium')`
    height: 53.5rem;
    max-width: 53.5rem;
  `}

  ${Media.greaterThan('large')`
    width: 53.5rem;
  `}
`;
