import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../Button';
import Text from '../Text';

import {
  ButtonContainer,
  ButtonsContainer,
  ImageContainer,
  TextContainer,
} from './styles';

export interface HomeProps {
  onChangeType: (type: string) => void;
}

const Home: React.FC<HomeProps> = React.memo(({ onChangeType }) => {
  return (
    <React.Fragment>
      <TextContainer>
        <Text as="h1" weight={600}>
          Inspiration comes from everywhere
        </Text>
        <Text as="h3" weight="normal">
          Instantly generate themes and extract design tokens from any source
        </Text>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              variant="primary"
              label="Generate from image"
              type="button"
              fullWidth
              onClick={() => onChangeType('image')}
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              variant="accent"
              label="Generate from website"
              type="button"
              fullWidth
              onClick={() => onChangeType('url')}
            />
          </ButtonContainer>
        </ButtonsContainer>
      </TextContainer>

      <ImageContainer>
        <StaticImage
          src="../../images/hero-image.png"
          alt="A sample theme"
          placeholder="blurred"
          width={1200}
        />
      </ImageContainer>
    </React.Fragment>
  );
});

export default Home;
