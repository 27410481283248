import React, { useCallback, useState, useMemo } from 'react';
import Validator from 'validator';
import { useDebounce } from 'use-debounce';
import { navigate } from 'gatsby';

import Button from '../Button';
import Error from '../Error';
import ImageLoader from '../../components/ImageLoader';
import Spacer from '../Spacer';
import Text from '../Text';

import { useCreateStyleExtractionByUrl } from './hooks/useCreateStyleExtractionByUrl';
import { useApiPaths } from '../../hooks/useApiPaths';

import {
  MobileContainer,
  DesktopContainer,
  TextContainer,
  Input,
  PreviewContainer,
} from './styles';

export interface StyleExtractionByUrlProps {
  onBack: () => void;
  onChangeToImage: () => void;
}

const StyleExtractionByUrl: React.FC<StyleExtractionByUrlProps> = React.memo(
  ({ onBack, onChangeToImage }) => {
    const apiPaths = useApiPaths();
    const [url, setUrl] = useState('');
    const [debouncedUrl] = useDebounce(url, 1000);
    const [error, setError] = useState<string>();
    const [isSubmitting, setSubmitting] = useState(false);
    const createStyleExtractionByUrl = useCreateStyleExtractionByUrl();

    const previewUrl = useMemo(() => {
      if (Validator.isURL(debouncedUrl)) {
        return debouncedUrl;
      }

      return undefined;
    }, [debouncedUrl]);

    const onUrlChange = useCallback((event) => {
      setUrl(event.target.value);
    }, []);

    const onSubmit = useCallback(async () => {
      try {
        setSubmitting(true);
        const { token } = await createStyleExtractionByUrl(url);
        navigate(`/theme/?t=${token}`);
      } catch (error) {
        setUrl('');
        setSubmitting(false);
        setError('An unexpected error occurred, please try again');
      }
    }, [url]);

    return (
      <React.Fragment>
        <MobileContainer>
          <div>
            <Button
              variant="gray"
              size="small"
              label="Back"
              type="button"
              onClick={onBack}
            />
            <Text as="h1" weight={600}>Generate theme from any website</Text>
            <Text as="h3" weight="normal">
              Extract colors, typographies, styling, and interface components from any website
            </Text>

            <Spacer small={{ spacing: 'large' }} />

            {!previewUrl && (
              <PreviewContainer>
                <Text as="h3" weight={500} textAlign="center">
                  Type a URL in the input field below to get started
                </Text>
                <Input
                  type="url"
                  value={url}
                  placeholder="Type your url here"
                  required
                  onChange={onUrlChange}
                />
              </PreviewContainer>
            )}

            {previewUrl && (
              <ImageLoader
                url={apiPaths.getWebsiteThumbnailUrl(previewUrl)}
                loadingMessage="Loading website..."
                errorMessage="An error occurred while loading the website 😱 Please try again"
                onClose={() => setUrl('')}
              />
            )}

            {error && (
              <React.Fragment>
                <Spacer small={{ spacing: 'small' }} />
                <Error>{error}</Error>
              </React.Fragment>
            )}

            <Spacer small={{ spacing: 'large' }} />
            <Button
              variant="accent"
              type="button"
              label={isSubmitting ? 'Loading...' : 'Generate theme'}
              disabled={!previewUrl || isSubmitting}
              fullWidth
              onClick={onSubmit}
            />
          </div>
        </MobileContainer>

        <DesktopContainer>
          <TextContainer>
            <Button
              variant="gray"
              size="small"
              label="Back"
              type="button"
              onClick={onBack}
            />
            <Text as="h1" weight={600}>Generate theme from any website</Text>
            <Text as="h3" weight="normal">
              Extract colors, typographies, styling, and interface components from any website
            </Text>

            {error && (
              <React.Fragment>
                <Spacer small={{ spacing: 'small' }} />
                <Error>{error}</Error>
              </React.Fragment>
            )}

            <Spacer small={{ spacing: 'large' }} />

            <Button
              variant="accent"
              type="button"
              label={isSubmitting ? 'Loading...' : 'Generate theme'}
              disabled={!previewUrl || isSubmitting}
              onClick={onSubmit}
            />
          </TextContainer>
          {!previewUrl && (
            <PreviewContainer>
              <Text as="h3" weight={500} textAlign="center">
                Type a URL in the input field below to get started
              </Text>
              <Input
                type="url"
                value={url}
                placeholder="Type your url here"
                required
                onChange={onUrlChange}
              />
            </PreviewContainer>
          )}

          {previewUrl && (
            <ImageLoader
              url={apiPaths.getWebsiteThumbnailUrl(previewUrl)}
              loadingMessage="Loading website..."
              errorMessage="An error occurred while loading the website 😱 Please try again"
              onClose={() => setUrl('')}
            />
          )}
        </DesktopContainer>
      </React.Fragment>
    );
  }
);

export default StyleExtractionByUrl;
