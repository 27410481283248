import { useApiPaths } from '../../../hooks/useApiPaths';

export const useCreateStyleExtractionByUrl = () => {
  return async (url: string) => {
    const apiPaths = useApiPaths();
    
    const formData = new FormData();
    formData.append('type', 'url');
    formData.append('url', url);

    const response = await fetch(apiPaths.getCreateStyleExtractionUrl(), {
      method: 'POST',
      body: formData,
    });

    const responseJson = await response.json();

    if (!responseJson.token) {
      throw new Error('No token returned');
    }

    return {
      token: responseJson.token,
    };
  };
}