import React from 'react';

import Icon from '../Icon';

import { Button } from './styles';

export interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon liga="cross_small" size={1} />
    </Button>
  );
};

export default CloseButton;
