import Styled from 'styled-components';

export const Button = Styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.lightGray};
  border: 1px solid ${props => props.theme.colors.lightGray};
  cursor: pointer;
  transition: 300ms;

  &:hover, &:focus, &:active {
    background-color: ${props => props.theme.colors.darkerLightGray};
  }
`;