import React, { useState } from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Home from '../components/Home';
import Navbar from '../components/Navbar';
import Main from '../components/Main';
import StyleExtractionByImage from '../components/StyleExtractionByImage';
import StyleExtractionByUrl from '../components/StyleExtractionByUrl';
import Footer from '../components/Footer';

export interface IndexRouteProps extends PageProps {}

const IndexRoute: React.FC<IndexRouteProps> = React.memo(() => {
  const [type, setType] = useState<string | undefined>();

  return (
    <Layout>
      <Meta
        title="Theme Generator"
        description="Instantly generate themes and extract design tokens from any source with Theme Generator"
      />

      <Navbar onLogoClick={setType} />

      <Main>
        {!type && <Home onChangeType={setType} />}

        {type === 'image' && (
          <StyleExtractionByImage
            onBack={() => setType(undefined)}
            onChangeToUrl={() => setType('url')}
          />
        )}

        {type === 'url' && (
          <StyleExtractionByUrl
            onBack={() => setType(undefined)}
            onChangeToImage={() => setType('image')}
          />
        )}
      </Main>

      <Footer />
    </Layout>
  );
});

export default IndexRoute;
