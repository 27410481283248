import Styled from 'styled-components/macro';

export const Error = Styled.div`
  background-color: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.white};
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 0.1rem 0.5rem;
  border-radius: 2px;
`;
