import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const TextContainer = Styled.section`

  ${Media.greaterThan('medium')`
    margin-right: ${(props: any) => props.theme.spacing.large};
  `}

  ${Media.greaterThan('large')`
    max-width: 44%;
    margin-top: -25rem;
  `}
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing.large};

  ${Media.greaterThan('large')`
    flex-direction: row;
    margin-top: ${(props: any) => props.theme.spacing.xlarge};
  `}
`;

export const ButtonContainer = Styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing.medium};

  ${Media.greaterThan('large')`
    flex-direction: row;
    margin-bottom: 0;
    margin-right: 2rem;
  `}
`;

export const ImageContainer = Styled.section`
  margin: 0 -4rem -4rem -4rem;

  ${Media.greaterThan('large')`
    margin: -5rem -18.5rem 0 0;
  `}
`;