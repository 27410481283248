import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

import diceLightPNG from '../../images/dice-light.png';

export interface ImageProps {
  url?: string;
}

export const Container = Styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26rem;
  width: 100%;
  background-image: url(${diceLightPNG});
  background-size: 2rem;
  border-radius: 4px;
  padding: 2rem;
  border: 3px solid ${props => props.theme.colors.lightGray};
  border-radius: 10px;

  ${Media.greaterThan('medium')`
    height: 53.5rem;
    max-width: 53.5rem;
  `}

  ${Media.greaterThan('large')`
    width: 53.5rem;
  `}
`;

export const Loader = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.mediumGray};
`;

export const Error = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${props => props.theme.colors.danger};
`;

export const Img = Styled.div<ImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const CloseButtonContainer = Styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 999;
`;
