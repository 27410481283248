import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const DropContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 26rem;
  width: 100%;
  background-color: ${props => props.theme.colors.lightYellow};
  border: 3px dashed ${props => props.theme.colors.yellow};
  border-radius: 10px;
  padding: ${props => props.theme.spacing.large};
  cursor: pointer;
  padding: 6rem;

  ${Media.greaterThan('medium')`
    height: 53.5rem;
    max-width: 53.5rem;
  `}

  ${Media.greaterThan('large')`
    width: 53.5rem;
  `}
`;

export const UploadButton = Styled.button`
  height: 4.7rem;
  min-height: 4.7rem;
  padding: 0 4rem;
  background-color: ${props => props.theme.colors.yellow};
  border: 1px solid ${props => props.theme.colors.yellow};
  font-family: ${props => props.theme.fonts.body.family};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${props => props.theme.black};
  border-radius: 10px;
  cursor: pointer;
`;