import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const MobileContainer = Styled.div`
  display: block;
  width: 100%;

  ${Media.greaterThan('medium')`
    display: none;
  `}
`;

export const DesktopContainer = Styled.div`
  display: none;
  
  ${Media.greaterThan('medium')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 4rem 0 10rem 0;
  `}
`;

export const TextContainer = Styled.div`
  width: 50%;
  padding-right: 4rem;
`;