import React, { useCallback, useState } from 'react';
import { navigate } from 'gatsby';

import FileUploader from '../FileUploader';

import { useCreateStyleExtractionByImage } from './hooks/useCreateStyleExtractionByImage';

import Button from '../Button';
import Error from '../Error';
import ImageLoader from '../ImageLoader';
import Spacer from '../Spacer';
import Text from '../Text';

import { MobileContainer, DesktopContainer, TextContainer } from './styles';

export interface StyleExtractionByImageProps {
  onBack: () => void;
  onChangeToUrl: () => void;
}

const StyleExtractionByImage: React.FC<StyleExtractionByImageProps> =
  React.memo(({ onBack, onChangeToUrl }) => {
    const [file, setFile] = useState<File>();
    const [error, setError] = useState<string>();
    const [isSubmitting, setSubmitting] = useState(false);
    const createStyleExtractionByImage = useCreateStyleExtractionByImage();

    const onFilesChange = useCallback((files: File[]) => {
      setFile(files[0]);
    }, []);

    const onSubmit = useCallback(async () => {
      try {
        setSubmitting(true);
        const { token } = await createStyleExtractionByImage(file as File);
        navigate(`/theme/?t=${token}`);
      } catch (error) {
        setFile(undefined);
        setSubmitting(false);
        setError('An unexpected error ocurred, please try again');
      }
    }, [file]);

    return (
      <React.Fragment>
        <MobileContainer>
          <div>
            <Button
              variant="gray"
              size="small"
              label="Back"
              type="button"
              onClick={onBack}
            />
            <Text as="h1" weight={600}>Generate theme from any image</Text>
            <Text as="h3" weight="normal">
              Upload an app or website screenshot, a moodboard, or a photo to generate a design theme
            </Text>

            <React.Fragment>
              {!file && (
                <FileUploader
                  disabled={isSubmitting}
                  onChange={onFilesChange}
                />
              )}

              {file && (
                <ImageLoader
                  url={URL.createObjectURL(file)}
                  onClose={() => setFile(undefined)}
                />
              )}

              {error && (
                <React.Fragment>
                  <Error>{error}</Error>
                  <Spacer small={{ spacing: 'small' }} />
                </React.Fragment>
              )}
            </React.Fragment>

            <React.Fragment>
              <Spacer small={{ spacing: 'large' }} />
              <Button
                variant="accent"
                type="button"
                label={isSubmitting ? 'Loading...' : 'Generate theme'}
                disabled={!file || isSubmitting}
                fullWidth
                onClick={onSubmit}
              />
            </React.Fragment>
          </div>
        </MobileContainer>
        <DesktopContainer>
          <TextContainer>
            <Button
              variant="gray"
              size="small"
              label="Back"
              type="button"
              onClick={onBack}
            />
            <Text as="h1" weight={600}>Generate theme from any image</Text>
            <Text as="h3" weight="normal">
              Upload an app or website screenshot, a moodboard, or a photo to generate a design theme
            </Text>

            {error && (
              <React.Fragment>
                <Error>{error}</Error>
                <Spacer small={{ spacing: 'small' }} />
              </React.Fragment>
            )}

            <React.Fragment>
              <Button
                variant="accent"
                type="button"
                label={isSubmitting ? 'Loading...' : 'Generate theme'}
                disabled={!file || isSubmitting}
                onClick={onSubmit}
              />
            </React.Fragment>
          </TextContainer>
          {!file && (
            <FileUploader disabled={isSubmitting} onChange={onFilesChange} />
          )}

          {file && (
            <ImageLoader
              url={URL.createObjectURL(file)}
              onClose={() => setFile(undefined)}
            />
          )}
        </DesktopContainer>
      </React.Fragment>
    );
  });

export default StyleExtractionByImage;
