import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import Icon from '../Icon';
import Group from '../Group';
import Text from '../Text';

import { DropContainer, UploadButton } from './styles';

const MEGA_BYTES_10 = 10 * 1024 * 1024;

export interface FileUploaderProps {
  onChange: (files: File[]) => void;
  disabled?: boolean;
  maxSize?: number;
}

const FileUploader: React.FC<FileUploaderProps> = React.memo(
  ({ onChange, disabled, maxSize = MEGA_BYTES_10 }) => {
    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        onChange(acceptedFiles);
      },
      [onChange]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: ['image/jpeg', 'image/png'],
      maxSize,
      maxFiles: 1,
      disabled,
      onDrop,
    });

    return (
      <DropContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Text as="h3" weight={500} textAlign="center">
          {isDragActive
            ? 'Drop the files here'
            : 'Drag and drop an image, or click here to select a file'}
        </Text>
        <UploadButton>
          <Group small={{ spacing: 'small' }}>
            <Icon liga="upload" size={1.4} />
            <span>Upload image</span>
          </Group>
        </UploadButton>
      </DropContainer>
    );
  }
);

export default FileUploader;
