import { useApiPaths } from '../../../hooks/useApiPaths';

export const useCreateStyleExtractionByImage = () => {
  return async (file: File) => {
    const apiPaths = useApiPaths();

    const formData = new FormData();
    formData.append('image', file, file.name);
    formData.append('filename', file.name);
    formData.append('type', 'image');

    const response = await fetch(apiPaths.getCreateStyleExtractionUrl(), {
      method: 'POST',
      body: formData,
    });

    const responseJson = await response.json();

    if (!responseJson.token) {
      throw new Error('No token returned');
    }

    return {
      token: responseJson.token,
    };
  };
}